import React from 'react';
import { Link } from 'gatsby';
import MenuSocial from 'components/Menu/Social';
import {
  StyledMenu,
  StyledMenuContainer,
  StyledMenuList,
  StyledMenuItem,
  StyledMenuinfo,
  StyledMenuContact
} from './styled';

const MenuMain = ({
  isMenuOpen,
  setMenuOpen,
  menuItems,
  pageContext
}) => {
  const { baseURL } = pageContext;

  const getLink = (slug, title) => {
    if (slug == 'blog') return 'https://blog.ateliware.com';

    if (baseURL === '/en/') {
      if (title == 'home') return baseURL;
      else return `${baseURL}${slug}`;
    } else {
      if (title == 'home') return baseURL;
      else return `/${slug}`;
    }
  };

  return (
    <StyledMenu
      onClick={() => {
        setMenuOpen(false);
      }}
      isMenuOpen={isMenuOpen}
    >
      <StyledMenuContainer>
        <StyledMenuList>
          {menuItems.map((item, index) => {
            const { title, slug, goTo, externalLink } = item;

            return (
              <StyledMenuItem key={`menu-header-${index}`}>
                {goTo ? (
                  goTo === 'jobs' ? (
                    <a
                      href={externalLink}
                      title={title}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {title}
                    </a>
                  ) : (
                    <a
                      href={
                        baseURL === '/en/'
                          ? `${baseURL}${goTo}`
                          : goTo
                      }
                      title={title}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {title}
                    </a>
                  )
                ) : (
                  <Link to={getLink(slug, title)} title={title}>
                    {title}
                  </Link>
                )}
              </StyledMenuItem>
            );
          })}
        </StyledMenuList>
        <StyledMenuinfo>
          <StyledMenuContact>
            <p>
              <a
                href="mailto:hello@ateliware.com"
                title="Envie um e-mail para ateliware"
                target="_blank"
                rel="noopener noreferrer"
              >
                hello@ateliware.com
              </a>
            </p>
            <p>
              <a
                href="tel:+554130102275"
                title="Fale conosco por telefone"
                target="_blank"
                rel="noopener noreferrer"
              >
                +55 41 3010.2275
              </a>
            </p>
          </StyledMenuContact>
          <MenuSocial />
        </StyledMenuinfo>
      </StyledMenuContainer>
    </StyledMenu>
  );
};

export default MenuMain;
