import styled from 'styled-components';
import { StyledWrapper } from 'assets/global/styled';
import colors from 'assets/global/colors';

export const StyledHeaderActions = styled.div`
  display: inline-block;

  button,
  a {
    &:first-child {
      margin-right: 100px;
      width: 14em;
      text-align: center;

      &:after {
        font-size: 24px;
      }
    }
  }

  @media screen and (min-width: 981px) {
    button,
    a {
      &:first-child {
        margin-right: 0;
      }
    }
  }

  @media screen and (max-width: 768px) {
    button,
    a {
      &:first-child {
        display: none;
      }
    }
  }
`;

export const StyledHeader = styled.header`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 100;
  display: block;
  width: 100%;
  padding: 12px 0;
  background-color: ${colors.white};
  transition: all 0.4s ease-out;

  ${StyledWrapper} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    max-width: inherit;
    padding: 0 60px;

    @media screen and (max-width: 980px) {
      padding: 0 24px;
    }
  }

  @media screen and (max-width: 980px) {
    padding: 24px 0;
  }

  @media screen and (max-width: 768px) {
    padding: 16px 0;
    background-color: ${colors.white};
  }
`;
