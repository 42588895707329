import styled from 'styled-components';
import colors from 'assets/global/colors';
import fonts from 'assets/global/fonts';

export const StyledMenuDesktop = styled.ul`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  flex-grow: 1;
  max-width: 600px;
  margin: 0 1rem 0 3rem;
  padding-left: 0;
  justify-content: space-between;

  @media screen and (max-width: 980px) {
    display: none;
  }
`;

export const StyledMenuDesktopItem = styled.li`
  display: block;
  font-size: 18px;
  font-weight: 300;
  color: ${colors.gray300};
  line-height: 1.8;
  font-family: ${fonts.secondary};

  a {
    color: ${colors.gray300};
    text-decoration: none;

    &:hover {
      color: ${colors.red300};
    }
  }

  .relative {
    position: relative;
  }
`;

export const StyledDropdownItem = styled.span`
  display: flex;
  flex-direction: row;
  column-gap: 0.4rem;

  &:hover {
    img {
      filter: saturate(1);
    }
  }

  img {
    width: 10px;
    height: auto;
    align-self: center;
    filter: saturate(0);
  }
`;

export const StyledDropdownItemWrapper = styled.div`
  position: absolute;
  background-color: white;
  padding: 1rem;
  flex-direction: column;
  row-gap: 0.5rem;
  display: none;

  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  a {
    padding: 0.5rem;

    &:not(:last-child) {
      border-bottom: solid 1px ${colors.gray50};
    }
  }
`;

export const StyledDropdownItemContainer = styled.div`
  &:hover ${StyledDropdownItemWrapper} {
    display: flex;
  }
`;
