import styled from 'styled-components';
import colors from 'assets/global/colors';
import fonts from 'assets/global/fonts';
import { lighten } from 'polished';

export const StyledButton = styled.button`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 7px;
  font-family: ${fonts.primary};
  font-size: 14px;
  border-radius: 2px;
  transition: all 0.2s ease-out;
  cursor: pointer;
  color: ${colors.gray300};
  border: 1px solid ${colors.gray300};
  outline: none;
  text-decoration: none;
  background-color: ${colors.white};
  line-height: 1.4;
  &:hover {
    color: ${colors.white};
    background-color: ${lighten(0.2, colors.red300)};
    border: 1px solid ${lighten(0.2, colors.red300)};
  }

  &:not(:last-child) {
    margin-right: 5px;
  }

  &.active {
    color: ${colors.white};
    border: 1px solid ${colors.red300};
    background-color: ${colors.red300};
    &:hover {
      color: ${colors.white};
      background-color: ${lighten(0.2, colors.red300)};
    }
  }
`;

export const StyledLanguagePicker = styled.div`
  margin-left: auto;
  margin-right: 35px;

  @media screen and (max-width: 980px) {
    margin: 0 auto;
  }

  @media screen and (max-width: 768px) {
    margin-right: 35px;
  }
`;
