import styled, { css } from 'styled-components';
import colors from 'assets/global/colors';
import fonts from 'assets/global/fonts';

export const StyledMenu = styled.nav`
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 100vh;
  transition: all 0.4s ease-out;
  overflow: auto;

  &:before {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    height: 100vh;
    transition: all 0.2s ease-in-out 0.4s;
    content: '';
  }

  ${(props) =>
    props.isMenuOpen
      ? css`
          opacity: 1;
          visibility: visible;
          transform: translateX(0);
          transform: translate3d(0, 0, 0);

          &:before {
            background-color: rgba(34, 34, 34, 0.4);
          }
        `
      : css`
          opacity: 0;
          visibility: hidden;
          transform: translateX(100%);
          transform: translate3d(100%, 0, 0);

          &:before {
            background-color: rgba(34, 34, 34, 0);
          }
        `}
`;

export const StyledMenuContainer = styled.div`
  position: relative;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 80%;
  height: 100%;
  background-color: ${colors.white};
  box-shadow: 10px 10px 20px 20px rgba(34, 34, 34, 0.2);

  @media screen and (max-width: 980px) {
    width: 100%;
  }
`;

export const StyledMenuList = styled.ul`
  display: block;
  width: 100%;
  padding: 100px;
  margin: 0;
  text-align: right;
  list-style-type: none;

  @media screen and (max-width: 768px) {
    padding-left: 50px;
  }

  @media screen and (max-width: 580px) {
    margin: -10px 0;
    padding-right: 24px;
    padding-left: 24px;
    text-align: center;
  }

  @media screen and (max-height: 700px and max-width: 768px) {
    padding: 100px 24px 24px 24px;
  }
`;

export const StyledMenuItem = styled.li`
  font-family: ${fonts.primary};
  font-size: 52px;
  font-weight: 700;
  color: ${colors.gray300};
  text-transform: lowercase;

  a {
    display: inline-block;
    color: ${colors.gray300};
    text-decoration: none;
    transition: all 0.2s ease-out;

    &:hover {
      color: ${colors.red300};
      transform: translateX(-10px);
      transform: translate3d(-10px, 0, 0);
    }
  }

  @media screen and (max-width: 580px) {
    margin: 10px 0;
    font-size: 32px;
  }

  @media screen and (max-height: 700px and max-width: 768px) {
    font-size: 18px;
  }
`;

export const StyledMenuinfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 100px 50px 100px;

  @media screen and (max-width: 1090px) {
    justify-content: flex-end;
  }

  @media screen and (max-width: 768px) {
    padding-right: 50px;
    padding-left: 50px;
  }

  @media screen and (max-width: 580px) {
    padding-right: 24px;
    padding-left: 24px;
  }
`;

export const StyledMenuContact = styled.div`
  display: block;

  p {
    margin: 5px 0;
    padding: 0;
    font-family: ${fonts.primary};
    font-size: 20px;

    a {
      position: relative;
      color: ${colors.red300};
      text-decoration: none;

      &:before,
      &:after {
        position: absolute;
        bottom: 0;
        width: 0;
        height: 2px;
        background-color: ${colors.red300};
        transition: all 0.3s linear;
        content: '';
      }

      &:before {
        left: 0;
      }

      &:after {
        right: 0;
      }

      &:hover {
        &:before,
        &:after {
          width: 100%;
        }
      }
    }
  }

  @media screen and (max-width: 1090px) {
    width: 100%;
    text-align: right;
  }

  @media screen and (max-width: 768px) {
    text-align: center;
  }

  @media screen and (max-height: 700px and max-width: 768px) {
    p {
      font-size: 14px;
    }
  }
`;
