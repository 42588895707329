import React from 'react';
import { social } from 'constants/index';
import { StyledFooterSocial, StyledFooterSocialLink } from './styled';
import IconGithub from 'assets/images/social/icon-github.svg';
import IconLinkedin from 'assets/images/social/icon-linkedin.svg';
import IconTwitter from 'assets/images/social/icon-twitter.svg';
import IconMeetup from 'assets/images/social/icon-meetup.svg';
import IconGoogleMaps from 'assets/images/social/icon-google-maps.svg';
import IconYouTube from 'assets/images/social/icon-youtube.svg';

const MenuSocial = () => {
  return (
    <StyledFooterSocial>
      <StyledFooterSocialLink
        href={social.github}
        title="Github"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={IconGithub} alt="Github" />
      </StyledFooterSocialLink>
      <StyledFooterSocialLink
        href={social.linkedin}
        title="LinkedIn"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={IconLinkedin} alt="LinkedIn" />
      </StyledFooterSocialLink>
      <StyledFooterSocialLink
        href={social.twitter}
        title="Twitter"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={IconTwitter} alt="Twitter" />
      </StyledFooterSocialLink>
      <StyledFooterSocialLink
        href={social.youtube}
        title="YouTube"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={IconYouTube} alt="YouTube" />
      </StyledFooterSocialLink>
      <StyledFooterSocialLink
        href={social.meetup}
        title="Meetup"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={IconMeetup} alt="Meetup" />
      </StyledFooterSocialLink>
      <StyledFooterSocialLink
        href={social.googlemaps}
        title="GoogleMaps"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={IconGoogleMaps} alt="GoogleMaps" />
      </StyledFooterSocialLink>
    </StyledFooterSocial>
  );
};

export default MenuSocial;
