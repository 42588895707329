import React from 'react';
import { Link } from 'gatsby';
import {
  StyledMenuDesktop,
  StyledMenuDesktopItem,
  StyledDropdownItem,
  StyledDropdownItemWrapper,
  StyledDropdownItemContainer
} from './styled';
import ChevronDown from 'assets/images/richcontent/chevron-down.svg';

const ItemWithDropdown = ({ item, baseURL }) => {
  const { title, slug, subMenuItems } = item;

  let linkTo;

  switch (true) {
    case baseURL === '/en/' && slug === 'blog':
      linkTo = `/${slug}`;
      break;
    case baseURL === '/en/' && title === 'home':
      linkTo = baseURL;
      break;
    case baseURL === '/en/':
      linkTo = `${baseURL}${slug}`;
      break;
    case title === 'home':
      linkTo = baseURL;
      break;
    default:
      linkTo = `/${slug}`;
      break;
  }

  if (slug === 'blog') {
    linkTo = 'https://blog.ateliware.com';
  }

  return (
    <StyledDropdownItemContainer>
      <Link to={linkTo} title={title} className={'relative'}>
        <StyledDropdownItem>
          {title}
          <img src={ChevronDown} alt="chevron down" />
        </StyledDropdownItem>
      </Link>

      <StyledDropdownItemWrapper>
        {subMenuItems.map((smi) => {
          let linkToItem;

          switch (true) {
            case baseURL === '/en/' && smi.slug === 'blog':
              linkToItem = `/${smi.slug}`;
              break;
            case baseURL === '/en/' && smi.title === 'home':
              linkToItem = baseURL;
              break;
            case baseURL === '/en/':
              linkToItem = `${baseURL}${smi.slug}`;
              break;
            case smi.title === 'home':
              linkToItem = baseURL;
              break;
            default:
              linkToItem = `/${smi.slug}`;
              break;
          }

          if (smi.slug === 'blog') {
            linkToItem = 'https://blog.ateliware.com';
          }
          return (
            <Link to={linkToItem} title={smi.title}>
              {smi.title}
            </Link>
          );
        })}
      </StyledDropdownItemWrapper>
    </StyledDropdownItemContainer>
  );
};

const MenuDesktop = ({ menuItems, pageContext }) => {
  const { baseURL } = pageContext;

  return (
    <StyledMenuDesktop>
      {menuItems.map((item, index) => {
        const {
          title,
          slug,
          goTo,
          externalLink,
          subMenuItems = []
        } = item;

        let linkTo;

        switch (true) {
          case baseURL === '/en/' && slug === 'blog':
            linkTo = `/${slug}`;
            break;
          case title === 'home' && baseURL === '/en/':
            linkTo = baseURL;
            break;
          case title === 'home':
            linkTo = `${baseURL}`;
            break;
          default:
            linkTo = `/${slug}`;
            break;
        }

        if (slug === 'blog') {
          linkTo = 'https://blog.ateliware.com';
        }

        return (
          <StyledMenuDesktopItem key={`menu-header-${index}`}>
            {goTo ? (
              goTo === 'jobs' ? (
                <a
                  href={externalLink}
                  title={title}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {title}
                </a>
              ) : (
                <a
                  href={
                    baseURL === '/en/' ? `${baseURL}${goTo}` : goTo
                  }
                  title={title}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {title}
                </a>
              )
            ) : subMenuItems.length > 0 ? (
              <ItemWithDropdown item={item} baseURL={baseURL} />
            ) : (
              <Link to={linkTo} title={title}>
                {title}
              </Link>
            )}
          </StyledMenuDesktopItem>
        );
      })}
    </StyledMenuDesktop>
  );
};

export default MenuDesktop;
