import React from 'react';
import { graphql, useStaticQuery, navigate } from 'gatsby';
import { StyledLanguagePicker, StyledButton } from './styled';

const LanguagePicker = ({ pageContext }) => {
  const { originalPath, locale } = pageContext;
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            languages
          }
        }
      }
    `
  );
  return (
    <StyledLanguagePicker>
      {site.siteMetadata.languages.map((language, index) => {
        return (
          <StyledButton
            key={`${language}-link-${index}`}
            aria-label={`Change Language to ${language}`}
            onClick={() => {
              navigate(
                language === 'pt'
                  ? `${originalPath}`
                  : `/${language}${originalPath}`
              );
              localStorage.setItem('locale', language);
              setTimeout(() => {
                window.location.reload();
              }, 500);
            }}
            type="button"
            className={locale === language ? 'active' : ''}
          >
            {language.toUpperCase()}
          </StyledButton>
        );
      })}
    </StyledLanguagePicker>
  );
};

export default LanguagePicker;
