import styled from 'styled-components';
import colors from 'assets/global/colors';

export const StyledFooterSocial = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -30px;

  @media screen and (max-width: 1090px) {
    width: 100%;
    margin-top: 50px;
  }

  @media screen and (max-width: 768px) {
    justify-content: center;
    margin: 20px 0 0 0;
  }
`;

export const StyledFooterSocialLink = styled.a`
  display: inline-block;
  margin: 0 30px;
  transition: all 0.2s ease-out;

  img {
    display: inline-block;
    width: 24px;
    height: 24px;
    fill: ${colors.gray300};
  }

  &:hover {
    transform: scale(1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  @media screen and (max-width: 768px) {
    margin: 0 15px;
    text-align: center;
  }

  @media screen and (max-width: 460px) {
    width: 33.33%;
    margin: 10px 0;
  }
`;
