import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import Logo from 'components/Logo';
import MenuDesktop from 'components/Menu/Desktop';
import LanguagePicker from 'components/LanguagePicker';
import Burger from 'components/Menu/Burger';
import MenuMain from 'components/Menu/Main';
import Button from 'components/Form/Button';
import { StyledWrapper } from 'assets/global/styled';
import { StyledHeader, StyledHeaderActions } from './styled';

const Header = ({ menu, pageContext }) => {
  const { baseURL } = pageContext;
  const { callToAction, menuItems } = menu;
  const [menuOpen, setMenuOpen] = useState(false);
  const [scrollPosition, setSrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setSrollPosition(position);
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, {
      passive: true
    });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  let scroll = scrollPosition >= 150;

  return (
    <StyledHeader scroll={scroll}>
      <StyledWrapper>
        <Logo baseURL={baseURL} />
        <MenuDesktop
          menuItems={menuItems}
          pageContext={pageContext}
        />
        <LanguagePicker pageContext={pageContext} />
        <StyledHeaderActions>
          <Button
            style={{ justifyContent: 'center' }}
            to={`${baseURL}contact`}
            as={Link}
            type="button"
            callToAction={true}
            withIcon={true}
          >
            {callToAction}
          </Button>
          <Burger
            isMenuOpen={menuOpen}
            onClick={() => {
              setMenuOpen(!menuOpen);
            }}
          />
        </StyledHeaderActions>
      </StyledWrapper>
      <MenuMain
        setMenuOpen={setMenuOpen}
        isMenuOpen={menuOpen}
        menuItems={menuItems}
        pageContext={pageContext}
      />
    </StyledHeader>
  );
};

export default Header;
